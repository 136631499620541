/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author danielCastano
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React, { useEffect, useState, useRef } from "react";

import approvedFull from "../img/approved.svg";
import "./styles.css";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Button,Modal } from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";

import picture from "../img/picture.svg"
import picture1 from "../img/picture-1.svg"
import CloseIcon from '@material-ui/icons/Close';
import sad from "../img/Sad.svg";
import promise from "../img/promise-2.svg";

import ReactLoading from "react-loading";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ReplayIcon from '@material-ui/icons/Replay';
import CallIcon from '@material-ui/icons/Call';

import {
  generateOpt,
  generateOptCall,
} from "../../../../infractructura/ApiPrestapolisPublic";

import ReactCodeInput from "react-code-input";

import SignatureCanvas from 'react-signature-canvas';
import ModalButtonPage from "../modal/ModalOneButton";

//tenser
import { drawMesh } from "../tensorFlow/utilies";

import * as tf from "@tensorflow/tfjs";
import fondo from "../img/faceDraw2.svg";
import check from "../img/check.svg";
import close from "../img/close.svg";
// OLD MODEL
//import * as facemesh from "@tensorflow-models/facemesh";

// NEW MODEL
import * as facemesh from "@tensorflow-models/face-landmarks-detection";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Webcam from "react-webcam";


const FirmApproved = ({handlePrev, data, dataComercio, documentNumber, id, setFirmaBase64, cuotaInicial, setOptCall, cuotas,diaPago, setOpt, opt, valorMonto, setMessageFront, sendValidation, codigoCel, telephone, setCodigoCel, setImgSrc, imgSrc}) => {

  const { code } = useParams();

  const cambioColorFront = (e) => {
    e.target.classList.add("color-front");
  };

  const [showLoad, setshowLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successfulUploadFrontSide, setSuccesFull] = useState(false);
  const [message, setMessage] = useState("");
  const [labelFront, setlabelFront] = useState("Foto Selfie ");

  const[validateCanvas, setValidateCanvas] = useState(false);

  const[validate, setValidate] = useState(false);
  const[validateTel, setValidateTel] = useState(null);

  const[rostro, setRostro] = useState(null);

  const[vacio, setVacio]=useState(null);

  const [timer,setTimer] = useState(0); 

  const [errroFile,seterrorFile] = useState(null);
  
  const goToHome = () => {
    let urlPrincipal = dataComercio.baas != false ? dataComercio.configuracionesBaas[0].value : dataComercio.configuracionesBaas

    if (urlPrincipal != null && urlPrincipal != "") {
      setTimeout(() => {
        window.location.replace(dataComercio.configuracionesBaas[0].value);
      });
    } else {
      setTimeout(() => {
        window.location.replace("https://www.prestapolis.com/");
      });
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isOpenModalSelfie, setIsOpenModalSelfie] = useState(false);

  const [coordValidate, setCoordValidate] = useState(true);
  const[topLeft, setTopLeft] = useState(false);
  const[topLeft2, setTopLeft2] = useState(false);

  const optRef = "";

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  //  Load posenet
  const runFacemesh = async () => {
    // OLD MODEL
    // const net = await facemesh.load({
    //   inputResolution: { width: 640, height: 480 },
    //   scale: 0.8,
    // });
    // NEW MODEL
    const net = await facemesh.load(facemesh.SupportedPackages.mediapipeFacemesh);
    setInterval(() => {
      detect(net);
    }, 2000);
  };

  const detect = async (net) => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      // OLD MODEL
      //       const face = await net.estimateFaces(video);
      // NEW MODEL
      const face = await net.estimateFaces({input:video});

      if(face.length != 0 && face[0] && face[0].faceInViewConfidence){
        setVacio(false);
        // Get canvas context
        
        if(face[0].mesh[477][0] > 50 && face[0].mesh[477][0] < 80){
          setCoordValidate(true);
        }
        else{
          setCoordValidate(false);
        };
        setRostro(face[0].faceInViewConfidence);
        if(face[0].faceInViewConfidence <= 1 && face[0].faceInViewConfidence > 0){
          
          setTopLeft(true);
        }else{
          setTopLeft(false);
        };
      }else{
        setVacio(true);
      }

      if(canvasRef != null){
      const ctx = canvasRef.current.getContext("2d");
      requestAnimationFrame(()=>{drawMesh(face, ctx)});
      }
    }
  };

  const handleOpenModalSelfie = (e) =>{
    setIsOpenModalSelfie(true);
  }

  const cerrarModalSelfie = (e) =>{
    setIsOpenModalSelfie(false);
    setTopLeft(false);
    setTopLeft2(false);
    setImgSrc(null);
  }

  useEffect(()=>{runFacemesh()}, []);

  const formatNumber = (num) => {
		return new Intl.NumberFormat(["ban", "id"]).format(num);
	};

  const dia = diaPago[8];

  const dia2 = diaPago[9];

  const dia3 = dia + dia2;

  const cerrarModal = (e) =>{
    setValidate(false);
  }

  const isCloseModal = (e) =>{
    setIsOpenModal(false);
  }

  const handleSend = (e) =>{
    sendValidation("aprobar");
    setValidate(true);
  }

  const handleOpenModal = (e) =>{
    setIsOpenModal(true);
    setOpt("");
  }

  const backStep = (e) => {
    handlePrev();
  };

  const handleSms = (e) => {
    generateSMS(id);
    setValidateTel(true);
  }

  const handleCall = (e) => {
    generateCall(id);
    setValidateTel(false);
  }

  const handleChange = opt => {
      opt.replace(" ", /^[a-zA-Z ]*$/)
      setOpt(opt);
  };

  const sigPad = useRef({});

  function clear(){
    sigPad.current.clear();
  }

  const generateSMS = (id) =>{
    setTimer(parseInt(59, 10));
    generateOpt(id)
        .then((response) => {
            if (response.codigoRespuesta === "0000") {
                setOpt(response.respuesta.opt);
            } else {
               
            }
        })
        .catch((error) => {
          
            if (error.status === 401) {
            }
        });
        setTimeout(() => {
          startTimer();
      }, 10);
  }
  
  function onEndCamvas() {
    const img = sigPad.current.toDataURL('image/png');
    setFirmaBase64(img);
    setValidateCanvas(true);
  }

  const generateCall = (id) =>{
    setTimer(parseInt(59, 10));
    generateOptCall(id)
        .then((response) => {
            if (response.codigoRespuesta === "0000") {
              setOptCall(response.respuesta.opt);
            } else {
             
            }
        })
        .catch((error) => {
           
            if (error.status === 401) {
                this.props.onSetAuthUser(null);
            }
        });
        setTimeout(() => {
          startTimer();
      }, 10);
  }

  const startTimer = () =>{
    if(timer > 0){
      timer = setInterval(() => {
        decrementTimer();
      },1000);
    }
  };

  let intervalRef = useRef();

  const decreaseNum = () => setTimer((prev) => prev - 1);

  useEffect(() => {
    if(timer > 0){
    intervalRef.current = setInterval(decreaseNum, 1000);
    }
    return () => clearInterval(intervalRef.current);
  },[timer]);

  const decrementTimer = () =>{
    if(timer > 0){
      intervalRef.current = setInterval(decreaseNum, 1000);
    }else{
      clearInterval(timer);
    }
  }

  const capture = React.useCallback( ()=> {
    if(topLeft === true ){

      if(coordValidate === true && vacio === false){
        const imageSrc = webcamRef.current.getScreenshot();
        setTopLeft2(true);
        setImgSrc(imageSrc);
        setIsOpenModalSelfie(false);
      }
    }
  }, [webcamRef, setImgSrc, topLeft, coordValidate, vacio]);

  const phone = `******${telephone.substr(6)}`;

  useEffect(async () => {
    if(imgSrc != null && topLeft2===true ){
    let doc = documentNumber;
    let nitComercio = data.nit
    
    const base64Response = await fetch(`${imgSrc}`);
    const fotoSelfie = await base64Response.blob();

      if (typeof doc === "string") {
        doc = doc.includes(".") ? doc.split(".") : doc.split(",");
        doc = doc.join("");
        doc = parseInt(doc, 10);
      }

    let ramdom = Math.floor(Math.round(Math.random() * (999 - 100) + 100));
    const formData = new FormData();
    formData.append(
      "file",
      fotoSelfie,
      doc + "_" + "Foto-Pagare" + "_" + ramdom + "." + "jpg"
    );
    if (doc !== "") {
      let url = data.comercio?.baas != false ? `${process.env.REACT_APP_FILES}?doc=Comercios/${nitComercio}/usuarios/${doc}` : `${process.env.REACT_APP_FILES}?doc=Usuario/${doc}`
      await fetch(url, {
        // content-type header should not be specified!
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
       
          if (response.respuesta) {
            //this.setState({ [loading]: false, [type]: response.mensaje });
              setSuccesFull(true);
              setshowLoad(false);
              setMessageFront(response.mensaje);
          } else {
            setSuccesFull(true);
           
            setMessage(response.mensaje);
            setLoading(false);
          }
        })
        .catch((error) => {
        
          setSuccesFull(true);
        });
    }
  }
  }, [topLeft2, imgSrc]);

  const onFileUpload = async (event, type, loading, message, filename) => {
    type = type === null || type === undefined ? "" : type;
   
    if (type === "frontSideDocument") {
      setshowLoad(true);
      
    }
    const file = event.target.files[0];

    if (
      imgSrc.type === "image/jpeg" ||
      imgSrc.type === "image/png" ||
      imgSrc.type === "image/jpg"
    ) {
      const formData = new FormData();
      let doc = documentNumber;
      if (typeof doc === "string") {
        doc = doc.includes(".") ? doc.split(".") : doc.split(",");
        doc = doc.join("");
        doc = parseInt(doc, 10);
      }
      //this.setState({ [loading]: true });
      setLoading(true);
      // const name = file.name.replace(' ', '-');
      let ramdom = Math.floor(Math.round(Math.random() * (999 - 100) + 100)); //Ramdom 3 dijistos
      const lastDot =
      imgSrc.name === undefined ? (imgSrc.name = "") : imgSrc.name.lastIndexOf(".");
      const ext = imgSrc.name.substring(lastDot + 1);
      formData.append(
        "imgSrc",
        imgSrc,
        doc + "_" + filename + "_" + ramdom + "." + ext
      );
      let msg = "";
      if (imgSrc.size < 10485760 && doc !== "") {
        let url = `${process.env.REACT_APP_FILES}?doc=Usuario/${doc}`;
        await fetch(url, {
          // content-type header should not be specified!
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.respuesta) {
              //this.setState({ [loading]: false, [type]: response.mensaje });
              if (type === "frontSideDocument") {
                setSuccesFull(true);
                setshowLoad(false);
                setlabelFront(filename);
                setMessageFront(response.mensaje);
              }else {
           
              }
            } else {
              if (type === "frontSideDocument") {
                setSuccesFull(true);
              } else {
                
              }
           

              setMessage(response.mensaje);
              setLoading(false);
            }
          })
          .catch((error) => {
       
            msg = "Error";
            setSuccesFull(true);
          });
      } else {
        if (type === "frontSideDocument") {
          setSuccesFull(false);
        } else {
         
        }
        event.target.classList.add("is-invalid");
        event.target.classList.remove("is-valid");
        msg =
          doc === ""
            ? "Debe diligenciar el documento primero"
            : "Tamaño no permitido maximo 10 MB, Unicamente archivos .pdf, .png, .jpg, .jpeg";
        setSuccesFull(true);
      }
    } else {
      if (type === "frontSideDocument") {
        setshowLoad(false);
        event.target.value = null;
        seterrorFile(true);
        setTimeout(() => {
          seterrorFile(false);
        }, 3000);
      }
    }
  };


  const countdown = 
    timer > 0
        ? `00:${timer}`
        : `00:0${timer}`;

  const countdown2 = 
    timer > 0
        ? `${timer}`
        : `${timer}`;

  const countRef = useRef(timer);
  countRef.current = timer;

  const handleClosePhoto = () => {
    setTopLeft(false);
    setTopLeft2(false);
    setImgSrc(null);
    setIsOpenModalSelfie(true);
  }

  const handleCheckPhoto = () => {
    setIsOpenModalSelfie(false); 
    setSuccesFull(true);
    setTopLeft2(false);
  }

  const handleCloseModalPhoto = () => {
    setTopLeft2(false);
    setTopLeft(false);
    setTopLeft2(false);
    setImgSrc(null);
  }

  return( 
    <div className="marginReject">
    <div className="textInicioFirm">
      <strong>Firma el pagaré electrónico</strong>
    </div>  
    <p style={{marginTop: "-0.1pt", marginLeft: "-10pt"}}>
      <a
        id="politics"
        href={`https://${process.env.REACT_APP_AWS_BUCKET}/CARTA_ACEPTACION_CREDITO.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        className="textMiddleFirm"
      >  
      <strong>    
      <u>Este es tu contrato de crédito</u>
      </strong>   
      </a> 
    </p>
    <div style={{textAlign: "center", marginLeft: "8pt", marginTop: "-8pt",}} className="textContenidoFirm">
      <p><strong>Escribe tu firma aquí</strong></p>
    </div>
    <div className="clausulasApproved">
    <SignatureCanvas
      penColor='#000'
      ref={sigPad}
      canvasProps={{ width: 270, height: 200, className: 'sigCanvas' }} 
      onEnd={onEndCamvas}
    />
    </div>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className="btn-atras"
      style={{ marginBottom: "20pt" }}s
      onClick={clear}
    >
      <strong>
        Borrar
      </strong>
    </Button>

    {
      <Modal
      open={isOpenModalSelfie}
      onClose={cerrarModalSelfie}
      >
      
      <div className="content-modal bQ_sN">
        
        <div lassName="bQ_jQ">
  
        <div className="bQ_sP3">
          <div className="letraPhoto"> Ubica tu rostro en la silueta, y luego espera para presionar el botón de captura en la barra inferior cuanto esté de color {<span style={{color:"#0A699E"}}>AZUL</span>}</div>
          <CloseIcon style={{marginLeft: "15pt"}} onClick={cerrarModalSelfie}/>
        </div>
        
        <Webcam
          ref={webcamRef}
          mirrored={false}
          className="bQ_jP"
          screenshotFormat="image/jpeg"
          screenshotQuality= "1"
        />

        <canvas
          ref={canvasRef}
          style={{
            display: "none",
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 20,
            width: 450,
            height: 470,
          }}
        />

        {coordValidate===true && vacio === false ?
        <div className="bQ_sP2">
        <RadioButtonCheckedIcon className="botonPhotos" onClick={capture}/>
        </div>:
        <div className="bQ_sP2">
        <RadioButtonCheckedIcon className="botonPhotos2"/>
        </div>}

        

      {coordValidate===true && vacio === false ?
        <img src={fondo} className="bQ_sL"/>:
        <img src={fondo} className="bQ_sL2"/>}
        {coordValidate===true && vacio === false ?
      <div className="bQ_sM"></div>:
      <div style={{background: "red"}} className="bQ_sM"></div>}
      </div>
    </div>
    
    </Modal>
    }

  {
    <Modal
        open={topLeft2}
        onClose={handleCloseModalPhoto}
        >
      
      <div className="content-modal bQ_sN">
        <div className="bQ_sP4">
        <div className="letraPhoto2"> ¡Captura realizada con éxito!</div>
          <CloseIcon style={{marginLeft: "15pt"}} onClick={handleCloseModalPhoto}/>
        </div>
        <img className="bQ_jP" src={imgSrc} />
        <div className="bQ_sP">
          <img src={close} alt="close" onClick={handleClosePhoto} />
          <img src={check} alt="check" onClick={handleCheckPhoto} />
        </div>
      </div>
      </Modal>
      }

    <div style={{ marginTop: "-10pt", marginBottom: "20pt" }} className="textContenidoApproved2">
      <p><strong>Tomate una foto tipo selfie con tu cédula en mano</strong></p>
    </div>
    {successfulUploadFrontSide === false ? (
    <div >
      <img
        className="logoPicture"
        alt="Logo"
        id="industriaycomerio"
        src={picture}
      />
    </div>): 
      (
        <div >
          <img
            className="logoPicture"
            alt="Logo"
            id="industriaycomerio"
            src={picture1}
          />
        </div>)
    }
    <div style={{width: "60%"}} class="container-input">
      {process.env.REACT_APP_ACTIVATE_TENSERFLOW === 'enabled' ?
        <input
        id="frontSideDocument"
        className="inputfile inputfile-2"
        required
        // defaultValue={datosAdd.frontSideDocument}
        onClick={handleOpenModalSelfie}
      />:
        <input
          type="file"
          id="frontSideDocument"
          className="inputfile inputfile-2"
          accept="image/jpeg,image/png, image/jpg,application/pdf"
          required
          // defaultValue={datosAdd.frontSideDocument}
          onChange={(e) => {
            onFileUpload(
              e,
              "frontSideDocument",
              "showLoadFrontSideFile",
              "messageFrontSide",
              "Lado frontal"
            );
          }}
          onClick={cambioColorFront}
        />
      }

      <label for="file-2" style={{ textTransform: "none", borderRadius: "100px", justifyContent: "center" }}>
        <span class="iborrainputfile"></span>
        <strong>
        {labelFront} 
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="iborrainputfile"
            width="20"
            height="17"
            viewBox="0 0 20 17"
          >
            <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
          </svg>
        </strong>
      </label>
      {showLoad === true ? (
        <div className="Loading justify-content-center">
          <ReactLoading
            type={"cylon"}
            color={"#000"}
            height={30}
            width={30}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
    <div style={{fontSize: "10pt"}} className="textContenidoApproved3">Evita tapar tu rostro con el documento, tus manos, mascarillas u otros objetos</div>
    <br></br>
    <div style={{textAlign: "center"}} className="textContenidoApproved2">
      <strong>Escribe el código enviado por mensaje de texto a tu teléfono móvil:</strong>
    </div>
    <br></br>
    <div style={{marginTop:"-12pt"}}>
    {!isOpenModal && <ReactCodeInput type='tel' autoFocus={false} fields={6} filterChars={['-', '.', '.',"a","s","z","q"]} value={optRef} onChange={handleChange}/>}
    </div>
    <br></br>
    <br></br>
    <div style={{textAlign: "center", marginTop: "-50pt", marginLeft: "-16pt"}}>
      <br></br>
      <a
        id="politics"
        onClick={handleOpenModal}              
        rel="noopener noreferrer"
        className="textMiddleFirm"
        
        >
            ¿Aún no has recibido tu código de aprobación o el digitado es incorrecto?
            <CallMadeIcon style={{ fontSize: "1rem"}}/>
        </a> 
    </div>
    <br></br>
    <div style={{textAlign: "center", marginTop: "-10pt",}} className="textContenidoCellEnd">
      Al hacer click en aprobación esta aceptando las condiciones del crédito
    </div>
    <br></br>
    <Button
      disabled={opt === "" || validate || successfulUploadFrontSide === false || validateCanvas === false}
      type="submit"
      variant="contained"
      color="primary"
      style={{ textTransform: "none", width: "14rem", borderRadius: "100px" }}
      className="MuiTypography-colorPrimary"
      onClick={(e) => handleSend()}
    >
      <strong>
        Acepto y Firmo
      </strong>
    </Button>
    <br></br>
    <br></br>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className="btn-atras"
      style={{ marginBottom: "20pt" }}
      onClick={(e) => backStep()}
    >
      <strong>
        Volver
      </strong>
    </Button>

    { validate && 
    <Modal
      open={!codigoCel.success && codigoCel.error === "EP009"}
      onClose={cerrarModal}
    >    
      <div className="modalDocumentValidation">
        <div>
          <img
            className="logoSad2"
            alt="Logo"
            id="industriaycomerio"
            src={sad}
          />
          <h3 className="textMiddleFirm2" style={{marginBottom: "5pt", fontSize: "16pt"}}>El código que digitó es incorrecto.</h3>
          <br></br>
        </div>  
        <div style={{textAlign: "center"}}> 
        <Button 
        variant="contained"
        color="primary"
        className="MuiTypography-colorPrimary"
        style={{ textTransform: "none", width: "14rem", borderRadius: "100px" }}
        onClick={(e) => {
          cerrarModal()
          setCodigoCel({})
        }}
        >Aceptar
        </Button>
        </div> 
      </div> 
    </Modal>
    }

    { validate &&
      <Modal
      open={codigoCel.success && !codigoCel.error}
      onClose={goToHome}
      >    
      <div className="modalDocumentValidation">
        <div>
          <img
            className="logoPromise"
            alt="Logo"
            id="industriaycomerio"
            src={promise}
          />
          <div className="textModalAprovate" style={{marginTop: "15pt", fontSize: "12pt", textAlign: "justify"}}>
            <div style={{fontSize: "18pt", textAlign: "center"}}><strong>Felicitaciones tu cupo ha sido aceptado</strong></div>
              <br></br>
              <div style={{color:"#707070"}}>
                <div style={{fontSize: "13pt", textAlign: "justify"}}>Pronto recibiras un correo con el pagaré y carta de pagaré que has firmado electrónicamente.</div>
                  <br></br>
                  Recuerda que tu {<span style={{fontWeight: "bold", color: "black"}}>cuota</span>} es de {<span style={{fontWeight: "bold", color: "black"}}>${formatNumber(Math.floor(cuotas))}</span>} y los dias de pago son los {dia3} de cada mes, Puedes consultar información de {<span style={{fontWeight: "bold", color: "black"}}>medios de pagos</span>} en { }
                  <a
                  id="politics"
                  href={`https://prestapolis.com/pagos`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{fontSize: "12pt", marginLeft:"0pt"}}
                  className="textModalAprovateLink"
                  >
                    <u>
                      https://sucursal.prestapolis.com
                    </u>
                  </a> 
                </div>
              </div>
              <br></br>
          </div>    
        <div style={{textAlign: "center"}}>
        <Button 
        variant="contained"
        color="primary"
        className="MuiTypography-colorPrimary"
        style={{ textTransform: "none", width: "14rem", borderRadius: "100px" }}
        onClick={(e) => {
          goToHome()
          setCodigoCel({})
        }}
        >Aceptar</Button>
        </div>
      </div> 
    </Modal>
    }

    <Modal
      open={isOpenModal}
      onClose={isCloseModal}
      >    
      <div className="modalDocumentValidation">
        <div >
          <div style={{textAlign: "end"}} ><CloseIcon onClick={isCloseModal}/></div>
          <h3 className="titleDocument" style={{marginBottom: "5pt", fontSize: "16pt", textAlign: "center", color: "#000000"}}>¿Por cúal medio desea recibir su código?</h3>
          <br></br>
        </div>
        <div style={{display: "flex"}}>
        <Button 
        disabled={timer !== 0}
        variant="contained"
        color="primary"
        className = "btn-cellphone"
        onClick={(e) => handleSms()}
        >SMS {" "}<ReplayIcon></ReplayIcon></Button>
        <Button
        disabled={timer !== 0}
        variant="contained"
        color="primary"
        className = "btn-cellphone"
        onClick={(e) => handleCall()}
        >Llamada <CallIcon></CallIcon></Button>
        </div>
        {timer !== 0 && validateTel ?
        <div style={{textAlign: "center"}} >
        <div className="titleDocument" style={{marginBottom: "-10t", fontSize: "16pt"}}>{`Un mensaje ha sido enviado al número de teléfono ${phone}`}</div>
        <br></br>
        <div className="titleDocument" style={{marginBottom: "-1pt", fontSize: "16pt"}}>{`Podrás enviar otro SMS en `}</div>
        <div className="titleDocument" style={{marginBottom: "-15t", fontSize: "16pt", color: "#0A699E"}}>{countdown}</div>
        <br></br>
        </div>
        : 
        <div style={{textAlign: "center"}} >
        <div className="titleDocument" style={{marginBottom: "-15t", fontSize: "16pt"}}>{``}</div>
        </div>}
        {timer !== 0 && !validateTel ?
        <div style={{textAlign: "center"}} >
        <div className="titleDocument" style={{marginBottom: "-10t", fontSize: "16pt"}}>{`Muy pronto recibirá una llamada a su número de teléfono ${phone}`}</div>
        <br></br>
        <div className="titleDocument" style={{marginBottom: "-1pt", fontSize: "16pt"}}>{`Podrás recibir otra llamada en`}</div>
        <div className="titleDocument" style={{marginBottom: "-15t", fontSize: "16pt", color: "#0A699E"}}>{countdown}</div>
        <br></br>
        </div>
        : 
        <div style={{textAlign: "center"}} >
        <div className="titleDocument" style={{marginBottom: "-15t", fontSize: "16pt"}}>{``}</div>
        </div>
        }
      </div> 
    </Modal>
    </div>
  )};

export default FirmApproved;