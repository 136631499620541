import React, { useEffect, useState} from "react";

import logo_prestapolis_color from "../img/logo_prestapolis_color.png";
import logoIC from "../img/logoIC.svg";
import Notification from "../img/Notification.svg";
import {
  getInfoByToken,
	getPlanById,
  sendUserValidate,
  generateOpt,
  sendPagareAprovate,
  getCommerceByName,
} from "../../../../infractructura/ApiPrestapolisPublic";
import { useParams } from "react-router-dom";
import ApprovedDate from "../Approved";
import FirmApproved from "../FirmApproved";
import ModalButtonPage from "../modal/ModalOneButton";
import { data } from "@tensorflow/tfjs";

export default function ValidationPage() {

  const { code } = useParams();

  const [activeSection, setActiveSection] = useState(1);

  const [id, setId] = useState("");

  const [documentNumber, setDocumentNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");

  const[valorMonto, setValorMonto] = useState("");
  const[cuotaInicial, setCuotaInicial] = useState("");
  const[costoPlataforma, setCostoPlataforma] = useState("");
  const[vsf, setVsf] = useState("");
  const[seguro, setSeguro] = useState("");
  const[cuotas, setCuotas] = useState("");
  const[meses, setMeses] = useState("");
  const[diaPago, setDiaPago] = useState("");
  const[interes, setInteres] = useState("");
  
  const [showLoad, setShowLoad] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isUsedToken, setIsUsedToken] = useState(false);
  const [codigoCel, setCodigoCel] = useState({});

  const [firmaBase64, setFirmaBase64] = useState("");
  const[opt, setOpt] = useState("");
  const[optCall, setOptCall] = useState("");
  const [messageFrontSide, setMessageFront] = useState("");
  const [tokenId,setTokenId]=useState("");
  const [plazo, setPlazo] = useState(null);

  const [tipoSolicitud, setTiposolicitud] = useState("");

  const [imgSrc, setImgSrc] = React.useState(null);
  
  const [idInteres, setIdInteres] = useState("");
  const [codigoPais, setCodigoPais] = useState("");
  const [interesAnual, setInteresAnual] = useState("");
  const [interesMensual, setInteresMensual] = useState("");
  const [interesDiario, setInteresDiario] = useState("");
  const [interesMoraAnual, setInteresMoraAnual] = useState("");
  const [interesMoraMensual, setInteresMoraMensual] = useState("");
  const [interesMoraDiario, setInteresMoraDiario] = useState("");
  const [fechaInteresVigente, setFechaInteresVigente] = useState("");
  const [fechaInteresCreada, setFechaInteresCreada] = useState("");
  const [fechaInteresActualizada, setFechaInteresActualizada] = useState("");
  const [costoPlataformaValidate, setCostoPlataformaValidate] = useState("");
  const [vsfValidate, setVsfValidate] = useState("");
  const [seguroValidate, setSeguroValidate] = useState("");
  const [stateNextValidation, setStateNextValidation] = useState(null);
  const [stateToken, setStateToken] = useState("");
  const [data, setData]= useState("");
  const [newComercio, setNewComercio] = useState(false);
  const [url, setUrl]= useState("");
  const[nameComercio, setNameComercio]= useState("");

  
 useEffect(async () => {
    await loadData(code);
    getComerceName(nameComercio);
  }, [nameComercio]); 

  useEffect( () => {
   
    getComerceName(nameComercio);
  }, [nameComercio]);

  const loadData = (code) =>{
		getInfoByToken(code)
			.then((response) => {
				let data = response.respuesta;
        setStateToken(response.codigoRespuesta);
				if (response.codigoRespuesta === "0000") {
          setData(data)
          setNameComercio(data.comercio.apikey)
          setCodigoPais(data.interes.codigopais);
          setIdInteres(data.interes.id);
          setInteresAnual(data.interes.interesanual);
          setInteresMensual(data.interes.interesmensual);
          setInteresDiario(data.interes.interesdiario);
          setInteresMoraAnual(data.interes.interesmoraanual);
          setInteresMoraMensual(data.interes.interesmoramensual);
          setInteresMoraDiario(data.interes.interesmoradiario);
          setFechaInteresVigente(data.interes.fechaInteresVigente);
          setFechaInteresCreada(data.interes.created);
          setFechaInteresActualizada(data.interes.updated);
          setCostoPlataformaValidate(data.costoPlataforma);
          setVsfValidate(data.vsf);
          setSeguroValidate(data.seguro);
          setCostoPlataforma(data.costoPlataforma)

					setDocumentNumber(response.respuesta.datosPersona.documentNumber);
          setFullName(response.respuesta.datosPersona.nombrecompleto);
          setEmail(response.respuesta.datosPersona.contactos[0].email);
          setTelephone(response.respuesta.datosPersona.contactos[0].cellphone);
          setValorMonto(response.respuesta.montoAprobado);
          setCuotaInicial(response.respuesta.abonoFinal);
          setMeses(response.respuesta.plazoFinal);
          setInteres(response.respuesta.interes.interesmensual);
          setTokenId(response.respuesta.token);
          setTiposolicitud(response.respuesta.tipoSolicitud.id);
          setShowLoad(false);

          if(data.comercio.baas == true){
            setNewComercio(true) 
          }

          
          if(cuotaInicial === 0){
            setPlazo(response.respuesta.plazoFinal)
          }else {
            setPlazo(response.respuesta.plazoFinal - 1)
          }
          
					if (data.statusToken) {
						setId(data.id);
						getPlanById(data.id)
							.then((respuesta) => {
								if (respuesta.codigoRespuesta === "0000" && respuesta.respuesta && respuesta.respuesta.length) {
                 // setCostoPlataforma(respuesta.respuesta[0].costoplataforma);
                  setVsf(respuesta.respuesta[0].vsf == 0 ? respuesta.respuesta[1].vsf : respuesta.respuesta[0].vsf);
                  setSeguro(respuesta.respuesta[0].seguro);
                  setCuotas(respuesta.respuesta[0].valorCuota);
                  setDiaPago(respuesta.respuesta[0].fechalimite);
								}
							})
							.catch((error) => {
							
							});
					} else {
            setShowLoad(true);
						setIsUsedToken(true);
					}
				} else {
          setShowLoad(true);
          
				} 
       
			})
			.catch((error) => {
				
			});
    
	}
 
  const getComerceName = () =>{
		 getCommerceByName(nameComercio)
			.then((response) => {
				let data = response.respuesta;
				if (response.codigoRespuesta === "0000") {
          setUrl(data)
  }})
			.catch((error) => {
				
			});
	}

const sendValidation = (type) => {
  if(type === "aprobar"){
  getInfoByToken(tokenId)
    .then((response) => {
      
      let datos = response.respuesta;
      let data = {
        dataPerson: {
          documentNumber: datos.datosPersona.documentNumber,
          nombrecompleto: datos.datosPersona.nombrecompleto,
        },
        contacts: {
          address: datos.datosPersona.contactos[0].address,
          city: datos.datosPersona.contactos[0].city,
          email: datos.datosPersona.contactos[0].email,
          cellphone: datos.datosPersona.contactos[0].cellphone,
          telefono: ""
        },
        credito: {
          valorcredito: datos.montoAprobado,
          valorCuota: cuotas,
          valorabono: datos.abonoFinal,
          primerPago: datos.resultadoSolicitud.abono,
          cuotas: plazo,
          costoPlataforma: costoPlataforma,
          vsf: vsf,
          seguros: seguro,
          plazo: plazo,
          fechaproximopago: datos.updated,
        },
        requests: {
          id: datos.id,
          interes: datos.interes,
          comercio:{
            id: datos.comercio.id,
            nombre: datos.comercio.nombre,
            email: datos.comercio.email,
            sent: datos.comercio.sent,
          },
          valormontoaprobado: datos.montoAprobado,
          opt: opt,

          tipoSolicitud: {
            id: datos.tipoSolicitud.id,
          },
        },

        token: tokenId,
        isValidate: true,
        firmaBase64: firmaBase64,
        fotoPagare: `https://${process.env.REACT_APP_AWS_BUCKET}/${messageFrontSide}`,
      };

      if (opt) {
        sendPagareAprovate(data)
          .then((res) => {
            if (res.codigoRespuesta === "0000") {
              setCodigoCel({success: true, error:null});
            }
            if(res.codigoRespuesta === "EP009"){
              setCodigoCel({success: false, error: res.codigoRespuesta});
            }
          })
      }
    })
  }
}

const nextValidation = () => {

  let request = {
    credito: {
        plazo: meses,
        valorabono: cuotaInicial,
    },
    requests: {
        interes: {
            id: idInteres,
            codigopais: codigoPais,
            interesanual: interesAnual,
            interesmensual: interesMensual,
            interesdiario: interesDiario,
            interesmoraanual: interesMoraAnual,
            interesmoramensual: interesMoraMensual,
            interesmoradiario: interesMoraDiario,
            fechaInteresVigente: fechaInteresVigente,
            created: fechaInteresCreada,
            updated: fechaInteresActualizada,
        },
        valormontoaprobado: valorMonto,
        costoPlataforma: costoPlataformaValidate,
        vsf: vsfValidate,
        seguro: seguroValidate,
    },
    token: tokenId,
    }
  
    sendUserValidate(request)
    .then((response) => {
      if (response.codigoRespuesta === "0000" ) {
        setStateNextValidation(true);
      }else{
        setStateNextValidation(false);
      }
    })
}

  const handleNext = (e) => {
    setActiveSection(activeSection + 1);
  };

  const handlePrev = (e) => {
    setActiveSection(activeSection - 1);
  };

  const handleActiveSection = () => {

		switch (activeSection) {
			case 1:
				return (
					<ApprovedDate
          documentNumber={documentNumber}
          fullName={fullName}
          email={email}
          telephone={telephone}
          valorMonto={valorMonto}
          cuotaInicial={cuotaInicial}
          setCuotaInicial={setCuotaInicial}
          costoPlataforma={costoPlataforma}
          data={data}
          vsf={vsf}
          seguro={seguro}
          cuotas={cuotas}
          meses={meses}
          diaPago={diaPago}
          interes={interes}
          handleNext={handleNext}
          tipoSolicitud={tipoSolicitud}

          nextValidation={nextValidation}
          stateNextValidation={stateNextValidation}
          stateToken={stateToken}
					/>
				);
        case 2:
				return (
					<FirmApproved
          handlePrev={handlePrev}
          documentNumber={documentNumber}
          id={id}
          setFirmaBase64={setFirmaBase64}
          firmaBase64={firmaBase64}
          setOpt={setOpt}
          opt={opt}
          dataComercio={url}
          data={data.sucursal.comercioBean}
          setOptCall={setOptCall}
          setMessageFront={setMessageFront}
          sendValidation={sendValidation}
          tokenId={tokenId}
          cuotaInicial={cuotaInicial}
          valorMonto={valorMonto}
          codigoCel={codigoCel}
          telephone={telephone}
          setCodigoCel={setCodigoCel}
          diaPago={diaPago}
          cuotas={cuotas}
         
          setImgSrc={setImgSrc}
          imgSrc={imgSrc}
					/>
				);
			default:
				return <div></div>;
		}

	};
  
    return (
      <div className="container-top">
        <div className={process.env.REACT_APP_BOX_PRESTAPOLIS}></div>
        <div className="container-text">
          <div className="logo">
            <div style={{marginRight: "60px", marginTop: "30px", marginBottom: "30px"}}>
                <br></br>
                <img alt="Logo" id="logo-prestapolis" 
                src={data.comercio?.baas != false || data.comercio?.logo  == " " ? "" || `https://${process.env.REACT_APP_AWS_BUCKET}/${data.comercio?.logo}` : `https://s3.amazonaws.com/cdn.prestapolis.com/logos/LOGO+FINAL_LOGO+CON+ISOTIPO.png`} />{" "}
            </div>
          </div>

          <div className="text-white"><b>Formulario de Aprobación</b></div>

          <div style={{marginLeft: "10pt"}}className="progressbar">
            <div className={activeSection == 1 ? "active" : "inactive"}>1</div>
            <div className={activeSection == 2 ? "active" : "inactive"}>2</div>
          </div>
          {showLoad?
          <div className="textTokenUsed">
            <img
                className="logoNotificacion2"
                alt="Logo"
                id="industriaycomerio"
                src={Notification}
              />
						<p>
						<strong>Este link ya ha sido utilizado para aprobación de la solicitud de crédito,
						en caso de requerir soporte comunicarse a: <p></p></strong>
						<strong><a style={{color:"#2BAEB3"}} href ="https://wa.me/573116952930">https://wa.me/573116952930</a> </strong>
						</p>
					</div>:
          <div className="form-confirm">{handleActiveSection()}</div>
          }
        </div>
        {!newComercio ?   (
          <div className="col-12 footer">
          <div className="info">
            <p>
              <strong>Evite ser victima de fraudes financieros</strong>
              <br></br>
              Conoce las recomendaciones de la SuperIntendencia Financiera
              haciendo click{" "}
              <a
                href="https://www.superfinanciera.gov.co/inicio/sala-de-prensa/advertencias-y-medidas-administrativas-por-ejercicio-ilegal-de-actividad-financiera/advertencias-por-suplantacion--10097510"
                rel="noopener noreferrer"
                target="_blank"
                className="links"
              >
                aquí
              </a>
              <br></br>
              &copy;2022 PrestaPolis S.A.S. todos los derechos reservados{" "}
              {`${process.env.REACT_APP_VERSION}`}{" "}
              <br></br>
              PRESTAPOLIS SAS de Colombia{" "}
              <a
                href="mailto:ayuda@prestapolis.com"
                rel="noopener noreferrer"
                target="_blank"
                className="links"
              >
                ayuda@prestapolis.com{" "}
              </a>{" "}
              NIT 901035049-1
              <a
                href="https://www.sic.gov.co/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <br></br>
                <img
                  className="logo2"
                  alt="Logo"
                  id="industriaycomerio"
                  src={logoIC}
                />{" "}
              </a>
            </p>
            </div>
            
        </div>
        ):(
        <div className="col-12 footer">
        <div className="info">
          <p>
            <strong>Evite ser victima de fraudes financieros</strong>
            <br></br>
            Conoce las recomendaciones de la SuperIntendencia Financiera
            haciendo click{" "}
            <a
              href="https://www.superfinanciera.gov.co/inicio/sala-de-prensa/advertencias-y-medidas-administrativas-por-ejercicio-ilegal-de-actividad-financiera/advertencias-por-suplantacion--10097510"
              rel="noopener noreferrer"
              target="_blank"
              className="links"
            >
              aquí
            </a>
            <br></br>
            &copy;2022 PrestaPolis S.A.S. todos los derechos reservados{" "}
            {`${process.env.REACT_APP_VERSION}`}{" "}
            <br></br>
            {`${data.comercio.nombre} de Colombia`}{" "}
            <a
              href="mailto:ayuda@prestapolis.com"
              rel="noopener noreferrer"
              target="_blank"
              className="links"
            >
            </a>{" "}
            {`NIT ${data.comercio.nit} - ${data.comercio.checkDigit}`}{" "}
            <a
              href="https://www.sic.gov.co/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <br></br>
              <img
                className="logo2"
                alt="Logo"
                id="industriaycomerio"
                src={logoIC}
              />{" "}
            </a>
          </p>
          </div>
          
      </div>)}
      

      </div>
    )
}
