import React, { useEffect, useState, createRef } from "react";

import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { HelpValidate } from "../../../../domain/help/HelpValidate";
import CreatableSelect from "react-select/creatable";
//import { Number, Currency } from "react-number-format"
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
//material Form
import { useTheme } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import FormControl from '@material-ui/core/FormControl';
import { TextField, Modal, Button } from "@material-ui/core";
import { styled } from '@mui/material/styles';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Slider from '@material-ui/core/Slider';

import "./CreatedStyles.css";

import {
	generatePLans,
	generatePLansNewComerce,
	getInfoByToken,
	getProductsByCommerceId,
} from "../../../../infractructura/ApiPrestapolisPublic";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { color } from "@mui/system";



const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	root: {
		display: 'flex',
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	btnCalcula: {
		'& > *': {
			margin: theme.spacing(1),
			height: 30,
			width: 800,
			fontSize: 10,
			fontWeight: "bold",

		},
		paddingTop: 15,
	},
	listItem: {
		whiteSpace: "normal",
	},
	buttonProgress: {
		color: 'primary',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 290,
	},

	margin: {
		margin: theme.spacing(2),
	},
	padding: {
		padding: theme.spacing(1)
	},
	centerv: {
		height: "100vh",
		display: "flex",
		alignItems: "center"
	},
	borderRadius: {
		borderRadius: '3%'
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	grid: {
		marginLeft: "5px",
		width: "200px"
	},
	conter:
	{
		height:"100px"
	}

}));

let storesObj = JSON.parse((process.env.REACT_APP_KEY_COMERCE));

// Get from object params to show different in from and disallow some object from form
function checkCommercePayload(obj) {

	// extract args from function
	const args = Array.prototype.slice.call(arguments, 1);

	// iterate object
	for (let i = 0; i < obj.length; i++) {

		// compare token come form url with token declare in process.env.REACT_APP_KEY_COMERCE Json
		if(obj[i][args[1]] === args[0]){
			return obj[i];
		}
	}
	return []
}

const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 50;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 200,

		},
	},
};

const LightTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: theme.palette.common.white,
	  color: 'rgba(0, 0, 0, 0.87)',
	  boxShadow: theme.shadows[1],
	  fontSize: 11,
	},
  }))(Tooltip);
  
  const useStylesBootstrap = makeStyles((theme) => ({
	arrow: {
	  color: '#f29f05',
	},
	tooltip: {
	  backgroundColor: '#f29f05',
	},
  }));
  
  function BootstrapTooltip(props) {
	const classes = useStylesBootstrap();
  
	return <Tooltip arrow classes={classes} {...props} />;
  }
  
  const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#f5f5f9',
	  color: 'rgba(0, 0, 0, 0.87)',
	  maxWidth: 220,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid #dadde9',
	},
  }))(Tooltip);

export default function EconomicDataSection({
	handleNext,
	handlePrev,
	commerceData,
	handleEconomicData,
	param,
	request,
	rechValorPro,
	isProductoIn,
	setisProductoIn,
	buttonIn,
	setbuttonIn,
	planes,
	setPlanes,
	isNewPro,
	setisNewPro,
	planSelect,
	setplanSelect,
	disContinuo,
	setdisContinuo,

	setMorasVigentes,
	setPrimerCredito,
	primerCredito,
	morasVigentes,
	isCheck,
	setIscheck,

	setCheckCredito,
	setDescriptionValues,
	setCheckMoras,
	checkCredito,
	checkMoras,
	descriptionValues,

	token,

	setValorMonto,
	setValorInicial,
}) {

	const classes = useStyles();
	const theme = useTheme();
	const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();

	const [isContinuo, setIsContinuo] = useState(true);
	const [isErrorContinue, setIsErrorContinue] = useState(false);
	const [isProducto, setisProducto] = useState(false)

	const [estruCuotas, setEstrucuotas] = useState({});
	const[newComercio, setNewComercio]= useState(Object.keys(checkCommercePayload(storesObj, commerceData.apikey, "token")).length > 0 ? !checkCommercePayload(storesObj, commerceData.apikey, "token")['products']:false);
	const [descriptionValue, setdescriptionValue] = useState(newComercio == true ?  "Libre inversion" : "")

	const [alertAbonoS, setAlerabono] = useState(false);
	const [messageAbono, setMessageAbono] = useState(false);

	const [interesCero, setInteresCero] = useState(null);

	const [info, setInfo] = useState(false);
	const[cardContend, setCardContend] = useState(commerceData.porcentaje_vsf == 0 ? false:true);
	
	
	

	let costo = param.filter((param) => param.key === "COSTO_PLATAFORMA");

	const [estados, setEstados] = useState({
		visible: false,
		showLoad: false,
		filter: "",
		title: "",
		message:
			"Debes calcular y seleccionar un plan de pago acorde a tus preferencias",
		type: "",
		catalogo: [0],
	});

	const [MAX, setMAX] = useState(commerceData.montoMaximo)
	const [MIN, setMIN] = useState(commerceData.montoMinimo)
	
	
	const [button, setbutton] = useState(false)
	const [showLoad, setshowLoad] = useState(false)
	const [monto, setMonto] = useState(false)
	

	const [calculaData, setcalculaData] = useState({
		textproducto: "",
		valueRequiredAmount: "",
		abonoInicial: "",

	})

	
	const [plans, setPlans] = useState([]);


	const [intemSelct, setintemSelct] = useState({
		plans: [],
		loanTerm: 0,
		costoPlataforma: 0,
		interes: 0,
		selected: false,
	});

	const [prodComerce, setprodComerce] = useState([]);
	const [selectCatalogo, setCatalogo] = useState([]);
	const [catalogoState, setcatalogoState] = useState(false);

	const [valorProducto, setvalorProducto] = useState("0");
	const [valorAbono, setValorAbono] = useState("");
	const [valorAbonoMinimo, setValorAbonoMinimo] = useState("");
	const [valorProductoAft, setvalorProductoAft] = useState("")
	const [minAbono, setminAbono] = useState(true);
	const [multiSelectProductos, setMultiSelectProductos] = useState([])
	let [options, setOptions] = useState(0);
	const [abonoCero, setabonoCero] = useState(false)
	const [logValueAbono, setLogValueAbono] = useState(0)
	const [planSliderSelected, setPlanSliderSelected] = useState(null);

	const [maxPrestamoNeto, setMaxPrestamoNeto] = useState(commerceData.comercioBean?.prestamo_neto != undefined ? commerceData.comercioBean?.prestamo_neto : commerceData.prestamo_neto )
	
	const [montoBaas, setMontoBaas] = useState(false)
	
	const refvalue = createRef();
	const valorAbonoRef = createRef();
	const nameProd = createRef();


	const handleRadioChange = (event) => {
		setCheckCredito(event.target.value);
	};

	const handleRadioChange2 = (event) => {
		setCheckMoras(event.target.value);
	};


	useEffect(() => {

		if (refvalue.current) {
			refvalue.current.focus();
		} if (nameProd.current) {
			nameProd.current.focus()
		}

	}, []);

	useEffect(() => {

		pushMultiProd();
		if (commerceData.tipoFormulario === "SIN_ABONO") {
			setIscheck(true)
		}
	}, [prodComerce])

	useEffect(async () => {
		//await filter("");
		//const tt = await loadCatalogo("");
		const id = commerceData.id;
		const res = await getProductsByCommerceId(id, "");
		let catalogo = [];
		setcatalogoState(false);
		if (res.codigoRespuesta === "0000") {
			for (var key in res.respuesta) {
				const item = res.respuesta[key]
				catalogo.push({
					...item,
					estructuraCuotas: JSON.parse(item.estructuraCuotas),
				})
			}
			setprodComerce(catalogo);
			setcatalogoState(true);
		}

		setintemSelct(planSelect);
		setvalorProducto(isNewPro);
		setIsContinuo(disContinuo);
		setValorAbonoMinimo(request.minAbono);
		setvalorProductoAft(request.valueRequiredAmount);
		setValorAbono(request.abonoInicial);

		// Validar si hay token para setear los datos usando servicio
		if (token) {
			const dataSolicitud = await getInfoByToken(token)


			if (dataSolicitud.codigoRespuesta === "0000") {
				setvalorProductoAft(formatNumber(dataSolicitud.respuesta.valormontorequerido));
				setValorAbono(formatNumber(dataSolicitud.respuesta.abono));
				setdescriptionValue(dataSolicitud.respuesta.descripcion);

				if (dataSolicitud.respuesta.descripcion != "") {

					let sPro = dataSolicitud.respuesta.descripcion.split(",");
					let multi = [];

					for (var k in sPro) {
						let isNuevo = true
						const p = sPro[k]

						for (var key in catalogo) {
							const item = catalogo[key]
							if (p != "" && p == item.nombreProducto) {
								multi.push({
									label: item.nombreProducto,
									value: item.excluidoIva === false ? item.valor : item.valorSinIva,
									id: item.id,
									descripcion: item.descripcion,
									descuento: item.descuento,
									excluidoIva: item.excluidoIva,
									estructuraCuotas: item.estructuraCuotas,
								});
								isNuevo = false
							} else {
								isNuevo = isNuevo
							}
						}

						for (var key in multi) {

							if (multi[key] == p) {
								isNuevo = false
							}

						}
						if (isNuevo == true && p != "") {

							multi.push({
								label: p,
								value: valorProductoAft,
								id: 0,
								descripcion: p,
								descuento: 0,
								excluidoIva: false,
								estructuraCuotas: 0,
							});


						}

					}

					setCatalogo(multi);
				}

			}
			if (refvalue.current) {
				refvalue.current.focus();
			} if (nameProd.current) {
				nameProd.current.focus()
			}
		}

		if (plans.length === 0 && planes.length > 0) {
			planes.map((item) =>
				plans.push({
					...item
				})
			);
		}

		if (Object.entries(estruCuotas).length === 0) {

			let esCuotas = commerceData.estructuraCuotas;
			if (typeof esCuotas === "string") {
				esCuotas = esCuotas.includes('"') ? esCuotas.split("") : esCuotas.split("");
				esCuotas = esCuotas.join("");
				esCuotas = JSON.parse(esCuotas)

			}
			setEstrucuotas(esCuotas);

		}

		if (calculaData.valueRequiredAmount === "") {

			setcalculaData({
				textproducto: catalogoState ? request.descripcion : request.selectProducto,
				valueRequiredAmount: request.valueRequiredAmount,
				abonoInicial: 0,
			});
		}

		/*if (selectCatalogo.length === 0) {
			let listPcatalogo = request.selectProducto;
			setCatalogo(listPcatalogo);

		}*/

	}, [])


	const handleChange = (event) => {

		setCatalogo(event);
		const producto = event != "" ? true : false;
		setisProducto(producto);
		setbutton(false);
		const elemento = event;
		//let options = 0;
		let nombreP = "";

		prodComerce.forEach((option) => {
			nombreP = option.nombreProducto;
			elemento.forEach((elemnt) => {
				options = nombreP === elemnt.label ?
					options + elemnt.value
					: elemnt.__isNew__ === true ? options = "0" : options;
			})
		});

		setvalorProducto(formatNumber(Math.floor(options)));
		setvalorProductoAft(formatNumber(Math.floor(options)));
		rechValorPro(options);

		let value = options;
		value = writeNumber(value);

		let valueAbono = ""
		let tipoForm = commerceData.tipoFormulario === undefined ? "NO_TIPO" : commerceData.tipoFormulario;

		if (!isCheck) {
			if (tipoForm === "ABONO_MINIMO_OBLIGATORIO") {

				valueAbono = value * (commerceData.baseDivisorAbono / 100)
				valueAbono = Math.round(valueAbono / 100) * 100;
				valueAbono = Math.trunc(valueAbono);
				valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");
				valueAbono = writeNumber(valueAbono);
				setValorAbonoMinimo(valueAbono);
				valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
				setValorAbono(valueAbono);
				setvalorProductoAft(value)
				setbutton(false);
				return

			} else if (tipoForm === "ABONO_MINIMO_SUGERIDO") {

				valueAbono = value * (commerceData.baseDivisorAbono / 100)
				valueAbono = Math.round(valueAbono / 100) * 100;
				valueAbono = Math.trunc(valueAbono);
				valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");
				valueAbono = writeNumber(valueAbono);
				valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
				setValorAbono(valueAbono);
				setvalorProductoAft(value);
				setLogValueAbono(valueAbono);
				setbutton(false);
				return

			} else if (tipoForm === "SIN_ABONO") {
				valueAbono = 0;
				setvalorProductoAft(value);
				setValorAbono(valueAbono);
				rechValorPro(options);
				setbutton(false);
				return
			} else {
				valueAbono = 0;
			}
		} else {

			valueAbono = value * (commerceData.baseDivisorAbono / 100)
			Math.trunc(valueAbono)
			valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");
			valueAbono = writeNumber(valueAbono);
			valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
			setbutton(false);
			setValorAbono(0);
			setvalorProductoAft(0);
			setLogValueAbono(valueAbono);
			return
		}

		if (value === 0) {
			setValorAbono(0)
			setvalorProducto(0)
			setvalorProductoAft(0)
			if (tipoForm === "ABONO_MINIMO_SUGERIDO" || tipoForm === "ABONO_MINIMO_OBLIGATORIO") {
				valorAbonoRef.current.value = "";
			}
			setValorAbonoMinimo(valueAbono);
			refvalue.current.focus();
			// refvalue.current.value = "";

		}

		valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");


		setvalorProductoAft(value);
		setValorAbono(parseInt(valueAbono, 10));
		setValorAbonoMinimo(valueAbono);


	};

	// const handledTextP = (event) => {
	// 	const producto = event.target.value != "" ? true : false;
	// 	setisProducto(producto);

	// }

	const removeFormat = (item) => {
		return item.toString().replaceAll(".", "").replaceAll(",", "");
	}


	const filter = async (filter) => {
		setEstados({ filter: filter });
		if (estados.catalogo = [0]) {
			await loadCatalogo("");
		}
	};

	const writeNumber = (text) => {
		if (!isNaN(text)) {
			return text;
		}
		return text.substring(0, text.length - 1);

	}






	const pushMultiProd = () => {
		let multi = [];
		prodComerce.map((item) => {

			multi.push({
				label: item.nombreProducto,
				value: item.excluidoIva === false ? item.valor : item.valorSinIva,
				id: item.id,
				descripcion: item.descripcion,
				descuento: item.descuento,
				excluidoIva: item.excluidoIva,
				estructuraCuotas: item.estructuraCuotas,
			});
		});
		setMultiSelectProductos(multi);

	}

	const formatCreateLabel = (inputValue) => `Agregar... ${inputValue}`;
	// const focusAbono = () => {
	// 	valorAbonoRef.current.focus();
	// 	//alert("Entro")
	// }

	const loadCatalogo = async (filter) => {

		const id = commerceData.id;
		let catalogo = [];
		setEstados({ showLoad: true });
		getProductsByCommerceId(id, filter.toLowerCase())
			.then((response) => {
				if (response.codigoRespuesta === "0000") {
					response.respuesta.map((item) =>
						catalogo.push({
							...item,
							estructuraCuotas: JSON.parse(item.estructuraCuotas),
						})
					);
					setprodComerce(catalogo);
					setcatalogoState(true);

				} else {

				}
			})
			.catch((err) => {
				setEstados({ visible: true });

			});
		setEstados({ showLoad: false });

	};


	const calculePlan = (data) => {
		
		let catalogoSelect = selectCatalogo[0] === undefined || selectCatalogo[0] === null ?
			[] : selectCatalogo[0];


		let esCuotasProducto = catalogoSelect.estructuraCuotas;


		let isMultiPro = selectCatalogo.length > 0 ? true : false;

		let options = [];
		setshowLoad(true);
		let valor = data.valueRequiredAmount != "" ? (token === undefined || token === '' ? data.valueRequiredAmount
				: valorProductoAft) :
			valorProductoAft;

		if (typeof valor === "string") {
			valor = valor.includes(".") ? valor.split(".") : valor.split(",");
			valor = valor.join("");
			valor = parseInt(valor, 10);
		}
		let abono = valorAbono;
		if (typeof abono === "string") {
			abono = abono.includes(".") ? abono.split(".") : abono.split(",");
			abono = abono.join("");
			abono = parseInt(abono, 10);
		}
let resultado = (valor - abono)
		setValorMonto(valor);
		setValorInicial(abono);

		let esCuotas =
			isMultiPro !== true  ? commerceData.estructuraCuotas : 
			esCuotasProducto === null || 
			esCuotasProducto === undefined || 
			esCuotasProducto === 0 ?
			commerceData.estructuraCuotas : esCuotasProducto



		if (typeof esCuotas === "string") {
			esCuotas = esCuotas.includes('"') ? esCuotas.split("") : esCuotas.split("");
			esCuotas = esCuotas.join("");
			esCuotas = JSON.parse(esCuotas)
		}


		setEstrucuotas(esCuotas);

		let request = {
			valor: valor,
			abono: abono,
			seguro: 0,
			porcentaje_vsf: commerceData.porcentaje_vsf,
			opciones: esCuotas,
			porcentaje_vsf: commerceData.porcentaje_vsf,
			seguro:0,
		};

		let tipoForm = commerceData.tipoFormulario === undefined ? "NO_TIPO" : commerceData.tipoFormulario;
		let valueAb = valorAbono == undefined ? 0 : valorAbono;
		let abonolimite = commerceData.commerceData?.valor_limite_inferior != undefined ? commerceData.commerceData?.valor_limite_inferior : commerceData.valor_limite_inferior
		valueAb = valueAb.toString().replaceAll(".", "").replaceAll(",", "");
		if (valorAbono === "0" && tipoForm === "ABONO_MINIMO_OBLIGATORIO") {
			setabonoCero(true);
			setbutton(false);
			setshowLoad(false);
		} else {

			setcalculaData({
				textproducto: !catalogoState ? data.textproducto : selectCatalogo,
				valueRequiredAmount: data.valueRequiredAmount,
				abonoInicial: 0,
			});

			setminAbono(true);

				let prestaNeto = commerceData.comercioBean?.prestamo_neto != undefined ? commerceData.comercioBean?.prestamo_neto : commerceData.prestamo_neto
				
				if(commerceData.baas == true && resultado > prestaNeto){
					setshowLoad(false);
					setMontoBaas(true);
					setMonto(false);
				}else if( valor < commerceData.montoMinimo || valor > commerceData.montoMaximo){
				
						setshowLoad(false);
						setMonto(true);
						setMontoBaas(false);
					
				}
				 else if (
				commerceData.tipoFormulario === "ABONO_MINIMO_OBLIGATORIO" && parseInt(valueAb, 10) < parseInt( valorAbonoMinimo, 10) ||
				commerceData.tipoFormulario === "ABONO_MINIMO_OBLIGATORIO" && parseInt(valueAb, 10) <= 0
			) {
				setshowLoad(false);
				setminAbono(false);
				setbutton(false);
				setabonoCero(false);

			} else if (
				commerceData.baas == false && commerceData.tipoFormulario === "ABONO_MINIMO_SUGERIDO" && parseInt(valueAb, 10) < 20000 &&
				commerceData.tipoFormulario === "ABONO_MINIMO_SUGERIDO" && parseInt(valueAb, 10) >= 1
			) {
				setAlerabono(true);
				setMessageAbono("El valor abono puede ser de 0, pero no puede ser menor de $20.000")
				setshowLoad(false);
				setbutton(false);

			} else if (
				commerceData.baas == true && commerceData.tipoFormulario === "ABONO_MINIMO_SUGERIDO" && parseInt(valueAb, 10) < abonolimite &&
				commerceData.tipoFormulario === "ABONO_MINIMO_SUGERIDO" && parseInt(valueAb, 10) >= 1
			) {
				setAlerabono(true);
				setMessageAbono(`El valor abono no puede ser menor a:$${formatNumber(Math.floor(abonolimite))}`)
				setshowLoad(false);
				setbutton(false);

			}else if (parseInt(valueAb, 10) > parseInt(valor, 10)) {

				setAlerabono(true);
				setMessageAbono("El valor abono, no puede ser mayor que el monto del producto");
				setshowLoad(false);
				setbutton(false);

			} else if (parseInt(valueAb, 10) < 20000 && isCheck != true) {

				setAlerabono(true);
				setMessageAbono("El valor abono, pero no puede ser menor de $20.000");
				setshowLoad(false);
				setbutton(false);

			} else {
				setminAbono(true);
				setMonto(false);
				setMontoBaas(false)
				
				if(Object.keys(checkCommercePayload(storesObj, commerceData.apikey, "token")).length > 0){
					generatePLansNewComerce(request, checkCommercePayload(storesObj, commerceData.apikey, "token")['rate'])
					.then((response) => {
						if (response.codigoRespuesta === "0000") {
							setbutton(true);
							setshowLoad(false);
							setInteresCero("respuesta", response.respuesta[0].interes.interesmensual);
							response.respuesta.length > 0 &&
								response.respuesta.forEach((option) => {
									options.push({
										...option,
										selected: "",
									});
								});
							//setEstados({ plans: options });
							setPlans(options);
							let inMax = response.respuesta[response.respuesta.length - 1].meses;
							setPlanSliderSelected(inMax);

							setintemSelct({
								plans: options,
								loanTerm: response.respuesta[response.respuesta.length - 1]?.meses,
								vsf: response.respuesta[response.respuesta.length - 1]?.vsf,
								costoPlataforma: esCuotas[response.respuesta.length - 1]?.costoPlataforma,

								interes: response.respuesta[response.respuesta.length - 1]?.interes,
								commission: esCuotas[response.respuesta.length - 1]?.comision,
								selected: true,
							})
							setplanSelect({
								plans: options,
								loanTerm: response.respuesta[response.respuesta.length - 1]?.meses,
								vsf: response.respuesta[response.respuesta.length - 1]?.vsf,
								costoPlataforma: esCuotas[response.respuesta.length - 1]?.costoPlataforma,
								interes: response.respuesta[response.respuesta.length - 1]?.interes,
								commission: esCuotas[response.respuesta.length - 1]?.comision,
								selected: true,
							})

						} else {							
							setEstados({ visible: true });
						}
						setshowLoad(false);
					})
					.catch((err) => {
						setshowLoad(false);

					});
				}else{
					generatePLans(request)
					.then((response) => {
						if (response.codigoRespuesta === "0000") {
							setbutton(true);
							setshowLoad(false);
							setInteresCero("respuesta", response.respuesta[0].interes.interesmensual);
							response.respuesta.length > 0 &&
								response.respuesta.forEach((option) => {
									options.push({
										...option,
										selected: "",
									});
								});
							//setEstados({ plans: options });
							setPlans(options);
							let inMax = response.respuesta[response.respuesta.length - 1].meses;
							setPlanSliderSelected(inMax);

							setintemSelct({
								plans: options,
								loanTerm: response.respuesta[response.respuesta.length - 1]?.meses,
								vsf: response.respuesta[response.respuesta.length - 1]?.vsf,
								costoPlataforma: esCuotas[response.respuesta.length - 1]?.costoPlataforma,

								interes: response.respuesta[response.respuesta.length - 1]?.interes,
								commission: esCuotas[response.respuesta.length - 1]?.comision,
								selected: true,
							})
							setplanSelect({
								plans: options,
								loanTerm: response.respuesta[response.respuesta.length - 1]?.meses,
								vsf: response.respuesta[response.respuesta.length - 1]?.vsf,
								costoPlataforma: esCuotas[response.respuesta.length - 1]?.costoPlataforma,
								interes: response.respuesta[response.respuesta.length - 1]?.interes,
								commission: esCuotas[response.respuesta.length - 1]?.comision,
								selected: true,
							})

						} else {							
							setEstados({ visible: true });
						}
						setshowLoad(false);
					})
					.catch((err) => {
						setshowLoad(false);
						
					});
				}
				
			}
		}

	};

	const selectPlan = (item, key) => {

		let options = [];
		plans.forEach((option) => {
			options.push({
				...option,
				selected: option.valor == option.valor ? "selected" : "disabled"

			});

			setIsContinuo(false);
			setdisContinuo(false);

		});
		let esCuotas = estruCuotas;

		setPlans(options);

		setintemSelct({
			plans: options,
			loanTerm: item?.meses,
			vsf: item?.vsf,
			costoPlataforma: esCuotas[key]?.costoPlataforma,
			
			interes: item?.interes,
			commission: esCuotas[key]?.comision,
			selected: true,
		})

		setplanSelect({
			plans: options,
			loanTerm: item?.meses,
			vsf: item?.vsf,
			costoPlataforma: esCuotas[key]?.costoPlataforma,
			interes: item?.interes,
			commission: esCuotas[key]?.comision,
			selected: true,
		})



	};

	const formatNumber = (num) => {
		return new Intl.NumberFormat(["ban", "id"]).format(num);
	};

	const onSubmit = (data) => {

	
		if (refvalue.current) {
			refvalue.current.focus();
		}

		setcalculaData({
			textproducto: data.textproducto,
			valueRequiredAmount: data.valueRequiredAmount,
			abonoInicial: 0,
		});

		if (catalogoState && valorProducto != "0") {
			data.valueRequiredAmount = valorProducto
		} else {
			setisNewPro(valorProducto);
		}
		//catalogoState && (data.valueRequiredAmount = valorProducto);

		calculePlan(data);
	}

	const nextStep = (e) => {
		const data = {

			valueRequiredAmount: calculaData.valueRequiredAmount == "" || calculaData.valueRequiredAmount == null ? valorProductoAft : calculaData.valueRequiredAmount,
			descripcion: calculaData.selectCatalogo,
			loanTerm: intemSelct.loanTerm,
			costoPlataforma: intemSelct.costoPlataforma,
			abonoInicial: valorAbono,
			plans: intemSelct.plans,
			selected: intemSelct.selected,
			interes: intemSelct.interes,
			minimo: commerceData.montoMinimo,
			maximo: commerceData.montoMaximo,
			catalogo: plans,
			producto: calculaData.textproducto,
			minAbono: valorAbonoMinimo,
			primer_credito: primerCredito,
			moras_vigentes: morasVigentes,
			vsf: intemSelct.vsf
		}

		if (checkCredito === "si") {
			setPrimerCredito(true);
		} else if (checkCredito === "no") {
			setPrimerCredito(false);
		}

		if (checkMoras === "si") {
			setMorasVigentes(true);
		} else if (checkMoras === "no") {
			setMorasVigentes(false);
		}

		handleEconomicData(data);
		setisProductoIn(true);
		setbuttonIn(true);
		setPlanes(plans);
		handleNext();
	}

	const focusInput = () => {

		{/*let tipoForm = commerceData.tipoFormulario === undefined ? "NO_TIPO" : commerceData.tipoFormulario;
		if (tipoForm === "ABONO_MINIMO_OBLIGATORIO" || tipoForm === "ABONO_MINIMO_SUGERIDO") {
			
			refvalue.current.focus()
			nameProd.current.focus()
			return valorAbonoRef.current.focus();
		}*/}

	}

	const checkQuestion = (value) => {

		if (value) {
			setValorAbono("0")
			valorAbonoRef.current.valor = "";
		} else {
			setValorAbono(logValueAbono)
		}

		setIscheck(value)
		setbutton(false);

	}

	return (
		<>
			<div className="confirm">

				<section className="section">

					<form onSubmit={handleSubmit(onSubmit)} autocomplete="off" name="economi">
						<div hidden={Object.keys(checkCommercePayload(storesObj, commerceData.apikey, "token")).length > 0 ?
							!(checkCommercePayload(storesObj, commerceData.apikey, "token")['products']) : false}>
						{catalogoState == false ? (
							<Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
								<Grid item md={true} sm={true} xs={true} >
									<TextField
										required
										label="Escribe el nombre de los productos"
										placeholder="Iphone"
										fullWidth
										id="input-select"
										name="textproducto"
										variant="outlined"
										color="primary"
										inputProps={{ ref: nameProd }}
										InputLabelProps={{
											shrink: true,
											className: "label__color",
										}}
										defaultValue={descriptionValue || request.descripcion}
										value={descriptionValue || request.descripcion}

										{...HelpValidate().setDescriptionValues(register('textproducto', {
											required: "El producto es requerido",
											pattern: {
												minLength: 3,
												message: "el nombre debe tener minimo 3"
											}
										}), setdescriptionValue, setisProducto)}
										// onChange={handledTextP}
										error={!!errors.textproducto}
										helperText={errors.textproducto?.message} />
								</Grid>
							</Grid>
						) : (
						
							<Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
								<Grid item md={true} sm={true} xs={true}>
									<div style={{ textAlign: "left", color: '2BAEB3', fontSize: "11pt", }}>

										Seleccione Producto
									</div>
									<CreatableSelect
										isMulti
										classNamePrefix="select"
										id="catalogo"
										name="catalogo"
										placeholder="Registra los productos"
										options={multiSelectProductos}
										value={selectCatalogo}
										formatCreateLabel={formatCreateLabel}
										onInputChange={(event) => {
											if (event === null || event.length <= 5) {
												filter(event);
											}
										}}
										onChange={(event) => {
											if (event === null || event.length <= 5) {
												handleChange(event)
											} else {
												alert("solo puedes agregar 5")
											}
										}}
									/>
								</Grid>
							</Grid>

						)}
						</div>
						
						{isProducto || isProductoIn ? (

							<div>
								{catalogoState == false || newComercio == true ? (
									<Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
											<Grid item md={true} sm={true} xs={true} >
											<TextField
											placeholder="Valor del producto"
											label="Valor productos"
											disabled={token == undefined || token == '' ? false : true}
											hidden={true}
											fullWidth
											name="valueRequiredAmount"
											type="tel"
											id="input-select"
											required={true}
											variant="outlined"
											InputLabelProps={{
												shrink: true,
												className: "label__color",
											}}
											inputProps={{ ref: refvalue }}
											defaultValue={request.valueRequiredAmount}
											value={valorProductoAft === 0 || valorProductoAft === ""? request.valueRequiredAmount : valorProductoAft || request.valueRequiredAmount}
											{...HelpValidate().formatNumberAmount(register('valueRequiredAmount'), setbutton, setValorAbono, commerceData, setValorAbonoMinimo, setvalorProductoAft, valorAbonoRef, isCheck, setLogValueAbono)}
											error={!!errors.valueRequiredAmount}
											helperText={errors.valueRequiredAmount?.message}
												/>
											</Grid>
									
									</Grid>

								) : (

									<Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select"  >

										{valorProducto === "0" && (

											<Grid item md={true} sm={true} xs={true} style={{ zIndex: "0" }} >
												<TextField
													placeholder="Valor del producto"
													label="Valor productos"
													fullWidth
													name="valueRequiredAmount"
													type="tel"
													id="input-select"
													variant="outlined"
													required={true}
													InputLabelProps={{
														shrink: true,
														className: "label__color",
													}}
													inputProps={{ ref: refvalue }}
													defaultValue={request.valueRequiredAmount}
													value={valorProductoAft === 0 || valorProductoAft === "" ? request.valueRequiredAmount : valorProductoAft || request.valueRequiredAmount}
													{...HelpValidate().formatNumberAmount(register('valueRequiredAmount'), setbutton, setValorAbono, commerceData, setValorAbonoMinimo, setvalorProductoAft, valorAbonoRef, isCheck, setLogValueAbono)}
													error={!!errors.valueRequiredAmount}
													helperText={errors.valueRequiredAmount?.message}
												/>
											</Grid>

										)
										}
										

										{valorProducto != "0" && (

											<Grid item md={true} sm={true} xs={true} style={{ zIndex: "0" }}>
												<TextField

													placeholder="Valor de productos"
													label="Valor de productos"
													fullWidth
													name="valueRequiredAmount"
													type="tel"
													id="input-select"
													variant="outlined"
													disabled={token == undefined || token == '' ? false : true}
													required={true}
													InputLabelProps={{
														shrink: true,
														className: "label__color",
													}}
													inputProps={{ ref: refvalue }}
													// inputProps={{ref: valorMultiRef}}
													//value={valorProducto === 0 || valorProducto === ""? false : request.valueRequiredAmount || valorProducto}
													value={valorProductoAft === 0 || valorProductoAft === "" ? request.valueRequiredAmount : (token == undefined || token == '' ? valorProducto : valorProductoAft) || request.valueRequiredAmount}
													defaultValue={request.valueRequiredAmount}
													{...HelpValidate().formatNumberAmount(register('valueRequiredAmount'), setbutton, setValorAbono, commerceData, setValorAbonoMinimo, setvalorProducto, setvalorProductoAft, valorAbonoRef, isCheck, setLogValueAbono)}
													{...HelpValidate().formatNumber(register('valueRequiredAmount'))}
													autoFocus={!!(valorProducto === "" || 0)}
													error={!!errors.valueRequiredAmount}
													helperText={errors.valueRequiredAmount?.message}
												/>
											</Grid>
										)}									
									</Grid>

								)}
								

								{commerceData.tipoFormulario != "SIN_ABONO" ?

									(
										<Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select"  >

											<Grid item md={true} sm={true} xs={true} style={{ zIndex: "0" }}>
												<TextField
													required
													variant="outlined"
													InputLabelProps={{
														shrink: true,
														className: "label__color",
													}}
													placeholder={commerceData.tipoFormulario != "ABONO_MINIMO_SUGERIDO" ? "Valor Abono" : "Abono Sugerido"}
													label={commerceData.tipoFormulario != "ABONO_MINIMO_SUGERIDO" ? "Valor Abono" : "Abono Sugerido"}
													fullWidth
													name="valorAbono"
													type="tel"
													id="input-select"
													color="primary"
													inputProps={{ ref: valorAbonoRef }}
													disabled={commerceData.tipoFormulario != "ABONO_MINIMO_SUGERIDO" ? false : isCheck}
													value={valorAbono || request.valorAbono}
													defaultValue={valorAbono || request.valorAbono}
													{...HelpValidate().formatNumberAbono(register('valorAbono', {
														// required: "El valor es requerido.",
													}), setValorAbono, commerceData, valorAbonoMinimo, setError, clearErrors, setminAbono, setbutton)}
													error={!!errors.valorAbono}
													helperText={errors.valorAbono?.message}
												/>
											</Grid>
										</Grid>

									) : (
										<div></div>
									)

								}
								{/* { commerceData.tipoFormulario === "ABONO_MINIMO_SUGERIDO" && <div style={{textAlign: "center", color:"#6cd0d6", fontSize:"12px"}}><b>El valor abono puede ser cero, pero no puede ser menor de $80.000</b></div>} */}

								{
									commerceData.tipoFormulario === "ABONO_MINIMO_SUGERIDO" &&

									<Grid container spacing={1} alignItems="flex-end" style={{ textAlign: "right", marginBottom: "20px", marginTop: "1px", }}  >
										{/* <Grid item>
											<AssignmentTurnedInIcon color="primary" />
										</Grid> */}
										<Grid item xs={3} md={3} sm={2} >
											{/* <FormControlLabel
											value="start"
											control={
											<Checkbox
												color="primary"
												checked={isCheck}
												onChange={(e)=>{{checkQuestion(e.target.checked)}}}

											/>}
											componentsProps={{fontSize: "5px"}}
											label="Desbloquea la casilla de cuota inicial sugerida y simula tu crédito con un abono"
											labelPlacement="start"
											/> */}
											<Checkbox


												checked={isCheck}
												fullWidth

												onChange={(e) => { { checkQuestion(e.target.checked) } }}
											/>
										</Grid>

										<Grid item xs={8} md={8} sm={8}>
											<div style={{ textAlign: "justify", fontSize: "14px", color: "#707070", marginTop: "3px" }}><b>Selecciona la casilla y simula tu crédito sin cuota inicial</b></div>
										</Grid>

									</Grid>
								}

								<div className="textPreguntas"><b>¿Tienes o has tenido crédito con entidades de servicios financieros?</b></div>
								<p id="terminos-p">
									* No son servicios financieros planes de telefonías, internet o ventas por catálogo
								</p>
								<Grid container spacing={1} alignItems="flex-end" className="radioButon" >
									<RadioGroup value={checkCredito} onChange={handleRadioChange} aria-label="credito" name="MuiFormGroup-root">
										<FormControlLabel value="si" control={<Radio checked={checkCredito === 'si'} />} label="Si" />
										<FormControlLabel value="no" control={<Radio checked={checkCredito === 'no'} />} label="No" />
									</RadioGroup>
								</Grid>

								<div className="textPreguntas2"><b>¿Tienes moras vigentes con alguna entidad?</b></div>
								<Grid container spacing={1} alignItems="flex-end" className="radioButon" >
									<RadioGroup value={checkMoras} onChange={handleRadioChange2} aria-label="moras" name="MuiFormGroup-root">
										<FormControlLabel value="si" control={<Radio checked={checkMoras === 'si'} />} label="Si" />
										<FormControlLabel value="no" control={<Radio checked={checkMoras === 'no'} />} label="No" />
									</RadioGroup>
								</Grid>

								<div className={classes.wrapper}>
									<Grid container justify="center" style={{ marginTop: '20px' }}>

										<Button
											disabled={checkCredito === null || checkMoras === null}

											type="submit"
											aria-label="add to shopping cart"
											variant="contained"
											style={{
												textTransform: "none",
												width: "14rem",
												borderRadius: "100px",
												padding: "1pt",
												color: '#FFFFFF',
												background: '#2BAEB3'
											}}

										>
											<strong>
												Calcular
											</strong>
										</Button>
									</Grid>
								</div>
							</div>
						) : (

							<div></div>
						)}





						{/* {monto ? (<label id="alert-comerce">{`Monto minimo $${formatNumber(Math.floor(MIN))}, Monto maximo $${formatNumber(Math.floor(MAX))}`}</label>) : (<div></div>)} */}

						{/* {!minAbono ? (<label id="alert-comerce" style={{paddingTop: "10px"}}>{`Abono Minimo $${formatNumber(Math.floor(valorAbonoMinimo))}, Abono Maximo $${formatNumber(Math.floor(removeFormat(valorProductoAft)))}`}</label>) : (<div></div>)} */}

						{monto && setTimeout(() => {
							setMonto(false)
							setMontoBaas(false)
						}, 3000) && <Alert severity="error" style={{ marginTop: "20px" }}>{`El valor del producto no puede ser menor a  $${formatNumber(Math.floor(MIN))}, El valor del producto no puede ser mayor a $${formatNumber(Math.floor(MAX))}`}</Alert>}

						{montoBaas && setTimeout(() => {
							setMontoBaas(false)
							setMonto(false)
						}, 3000) && <Alert severity="error" style={{ marginTop: "20px" }}>{`El prestamo neto maximo es de  $${formatNumber(Math.floor(maxPrestamoNeto))}`}</Alert>}

						{!minAbono && valorAbono != 0 ? (<Alert severity="error" style={{ marginTop: "20px" }}>{`Abono Minimo ${commerceData.comercioBean.valor_limite_inferior != 0 ? commerceData.comercioBean.valor_limite_inferior : formatNumber(Math.floor(valorAbonoMinimo))}, Abono Maximo $${formatNumber(Math.floor(removeFormat(valorProductoAft)))}`}</Alert>) : (<div></div>)}

						{!minAbono && valorAbono === 0 ? (<Alert severity="error" style={{ marginTop: "20px" }}>{`El abono es requerido`}</Alert>) : (<div></div>)}
						{abonoCero && setTimeout(() => {
							setabonoCero(false)
						}, 2000) && <Alert severity="error" style={{ marginTop: "20px" }}>{`El abono es requerido`}</Alert>}


						{alertAbonoS && setTimeout(() => {
							setAlerabono(false)
						}, 3500) && <Alert severity="error" style={{ marginTop: "20px" }}>{messageAbono}</Alert>}

						{showLoad === true ? (
							<div className="Loading justify-content-center">
								<ReactLoading
									type={"spokes"}
									color={"#000"}
									height={50}
									width={50}
									className="loading-creat"
								/>
							</div>
						) : (button === true || buttonIn ? (


							<div id="list-select-plan" className="row">
								<p id="discrete-slider-custom" style={{ marginTop: "10pt", maxWidth: "8rem" }} gutterBottom>
									<strong>
										Plazo - meses
									</strong>
								</p>
								{plans.length > 0 && <Slider
									getAriaValueText={value => `${value}`}
									aria-labelledby="discrete-slider-custom"
									value={planSelect.loanTerm ? planSelect.loanTerm : planSliderSelected}
									step={null}
									marks={!isCheck ? plans.map(({ meses }) => ({ value: meses, label: `${meses - 1}` })) : plans.map(({ meses }) => ({ value: meses, label: `${meses}` }))}
									max={plans[plans.length - 1].meses}
									min={plans[0].meses}
									onChange={(_event, value) => {
										const key = plans.findIndex(({ meses }) => meses === value);
										const item = plans[key];
										setPlanSliderSelected(value);
										selectPlan(item, key);
									}}
								/>}
								<p id="discrete-slider-custom" style={{ marginTop: "8pt", maxWidth: "40.00rem", marginLeft: "auto", marginRight: "auto" }} gutterBottom>
									<strong>
										Haz click y selecciona tu cuota a pagar
									</strong>
								</p>
								{plans.length > 0 && plans.filter(({ meses }) => {
									if (planSelect.loanTerm) {
										return meses === planSelect.loanTerm
									}
									return meses === planSliderSelected
								})
								.map((item, key) => {
									return (<div
										className="select-plan"
										key={key}
										
										onClick={(e) => {
											selectPlan(item, plans.length - 1);
											
										}}
									>
									{cardContend == true ? (
									<div className={`form-field-Prestapolis-${item.selected} form-control `}>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
											<div style={{ marginTop: "5pt" }}>
												<b className="text-planes" style={{ fontSize: '28px' }}>
													${`${formatNumber(Math.floor(item.valor))}`}
												</b>
											</div>
											<div className={`select-plan2 ${item.selected}`} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
												<div>
													<CalendarTodayIcon style={{ fontSize: '1.7rem', marginTop: "2px", color: '#F29F05' }} />
												</div>
												<div style={{ marginBottom: "2pt", color: '#F29F05' }} >
													<b className="text-planes" style={{ fontSize: '1rem' }}>
														{`${valorAbono != "0" ? item.meses - 1 : item.meses} meses`}
													</b>
												</div>
											</div>
								
										</div>
									
								
										<Typography className={`linea ${item.selected}`} component="div" />
										<Grid >
											<Grid container spacing={1} alignItems="center" justify="center" >
												<Grid  >
													<Brightness1Icon style={{ color: '#2BAEB3' }} className={`punto ${item.selected}`} />
												</Grid>
												<Grid style={{ fontSize: '15px', textAlign: 'justify', marginLeft: '1%' }} item xs={8} >
													<b>
														{`Interes: ${((item.interes.interesmensual).toFixed(3))}% mes vencido*`}
													</b>
												</Grid>
												<Grid item xs={1} >
													<div align="right">
														<BootstrapTooltip
															title={
																<React.Fragment>
																	<a style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}><br />{"-Tasa de interés:"}</a>{' '}
																	<br />
																	<a style={{ fontSize: '16px', color: 'white', textAlign: 'justify' }}>
																		{"Este valor garantiza la disponibilidad de tu cupo de crédito, cubre gastos de instrumentación digital, disponibilidad de plataforma de recaudo y se paga mensualmente mientras tengas uno o más créditos activos"}
																	</a>
																	<br />
																	<br />
																</React.Fragment>
															}
															placement="left-start"
														>
															<InfoIcon style={{ color: '#F29F05' }} className={`punto ${item.selected}`} />
														</BootstrapTooltip>
													</div>
												</Grid>
											</Grid>
											
											<Grid container spacing={1} alignItems="center" justify="center" >
												<Grid  >
													<Brightness1Icon style={{ color: '#2BAEB3' }} className={`punto ${item.selected}`} />
												</Grid>
												<Grid style={{ fontSize: '15px', textAlign: 'justify', marginLeft:'1%' }} item xs={8} >
													<b>
													{`Fianza FGA +IVA: $${formatNumber(Math.floor( (item.vsf)/(isCheck == true ? item.meses : item.meses -1)))} por cuota`}
													</b>
												</Grid>
												<Grid item xs={1} >
													<div align="right">
														<BootstrapTooltip
															title={
																<React.Fragment>
																	<a style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}><br />{"-Valor servicio fianza + IVA (opcional):"}</a>{' '}
																	<br />
																	<a style={{ fontSize: '16px', color: 'white', textAlign: 'justify' }}>
																		{"Para el otorgamiento del crédito, debes cumplir con el requisito del Fondo de Garantías de Antioquia (FGA), este valor avala y respalda tu cupo de crédito si es aprobado. Este valor varía dependiendo del monto que pidas. Puede ser de hasta el 17% de tu cupo. Incluye IVA."}
																	</a>
																	<br />
																	<br />
																</React.Fragment>
															}
															placement="left-start"
														>
															<InfoIcon style={{ color: '#F29F05' }} className={`punto ${item.selected}`} />
														</BootstrapTooltip>
													</div>
												</Grid>
											</Grid>
											
											<Grid container spacing={1} alignItems="center" justify="center" >
												<Grid  >
													<Brightness1Icon style={{ color: '#2BAEB3' }} className={`punto ${item.selected}`} />
												</Grid>
												<Grid style={{ fontSize: '15px', textAlign: 'justify', marginLeft:'1%' }} item xs={8} >
												<b>
													{`Costo plataforma: $${formatNumber(Math.floor(item.costo / item.meses))} por cuota`}
													</b>
												</Grid>
												<Grid item xs={1} >
													<div align="right">
														<BootstrapTooltip
															title={
																<React.Fragment>
																	<a style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}><br />{"-Costo de plataforma:"}</a>{' '}
																	<br />
																	<a style={{ fontSize: '16px', color: 'white', textAlign: 'justify' }}>
																		{"Este valor garantiza la disponibilidad de tu cupo de crédito, cubre gastos de instrumentación digital, disponibilidad de plataforma de recaudo y se paga mensualmente mientras tengas uno o más créditos activos."}
																	</a>
																	<br />
																	<br />
																</React.Fragment>
															}
															placement="left-start"
														>
															<InfoIcon style={{ color: '#F29F05' }} className={`punto ${item.selected}`} />
														</BootstrapTooltip>
													</div>
												</Grid>
											</Grid>
											
											<Grid container spacing={1} alignItems="center" justify="center" >
												<Grid  >
													<Brightness1Icon style={{ color: '#2BAEB3' }} className={`punto ${item.selected}`} />
												</Grid>
												<Grid style={{ fontSize: '15px', textAlign: 'justify', marginLeft:'1%' }} item xs={8} >
													<b>
													Seguro: ${formatNumber(Math.floor(item.seguro == null ? "0" : item.seguro))}
													</b>
												</Grid>
												<Grid item xs={1} >
													<div align="right">
														<BootstrapTooltip
															title={
																<React.Fragment>
																	<a style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}><br />{"-Seguro:"}</a>{' '}
																	<br />
																	<a style={{ fontSize: '16px', color: 'white', textAlign: 'justify' }}>
																		{"Este valor corresponde a la póliza de aseguramiento sobre el valor del crédito en caso de muerte o incapacidad total del titular. Si cuentas con un seguro que cubra este tipo de obligaciones puedes utilizarlo con nosotros."}
																	</a>
																	<br />
																	<br />
																</React.Fragment>
															}
															placement="left-start"
														>
															<InfoIcon style={{ color: '#F29F05' }} className={`punto ${item.selected}`} />
														</BootstrapTooltip>
													</div>
												</Grid>
											</Grid>															
												<div style={{ marginTop: "5pt" }}>
													<b className="text-planes" style={{ fontSize: '24px' }}>
													Total a pagar ${`${formatNumber(Math.floor(item.pagoTotal))}`}
													</b>
													<br />										
												</div>																												
										</Grid>
									</div>):(
									<div className={`form-field-Prestapolis-${item.selected} form-control `}>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
											<div style={{ marginTop: "5pt" }}>
												<b className="text-planes" style={{ fontSize: '28px' }}>
													${`${formatNumber(Math.floor(item.valor))}`}
												</b>
											</div>
											<div className={`select-plan2 ${item.selected}`} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
												<div>
													<CalendarTodayIcon style={{ fontSize: '1.7rem', marginTop: "2px", color: '#F29F05' }} />
												</div>
												<div style={{ marginBottom: "2pt", color: '#F29F05' }} >
													<b className="text-planes" style={{ fontSize: '1rem' }}>
														{`${valorAbono != "0" ? item.meses - 1 : item.meses} meses`}
													</b>
												</div>
											</div>
								
										</div>
									
								
										<Typography className={`linea ${item.selected}`} component="div" />
										<Grid >
											<Grid container spacing={1} alignItems="center" justify="center" >
												<Grid  >
													<Brightness1Icon style={{ color: '#2BAEB3' }} className={`punto ${item.selected}`} />
												</Grid>
												<Grid style={{ fontSize: '15px', textAlign: 'justify', marginLeft: '1%' }} item xs={8} >
													<b>
														{`Interes: ${((item.interes.interesmensual).toFixed(3))}% mes vencido*`}
													</b>
												</Grid>
												<Grid item xs={1} >
													<div align="right">
														<BootstrapTooltip
															title={
																<React.Fragment>
																	<a style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}><br />{"-Tasa de interés:"}</a>{' '}
																	<br />
																	<a style={{ fontSize: '16px', color: 'white', textAlign: 'justify' }}>
																		{"Este valor garantiza la disponibilidad de tu cupo de crédito, cubre gastos de instrumentación digital, disponibilidad de plataforma de recaudo y se paga mensualmente mientras tengas uno o más créditos activos"}
																	</a>
																	<br />
																	<br />
																</React.Fragment>
															}
															placement="left-start"
														>
															<InfoIcon style={{ color: '#F29F05' }} className={`punto ${item.selected}`} />
														</BootstrapTooltip>
													</div>
												</Grid>
											</Grid>
											
											<Grid container spacing={1} alignItems="center" justify="center" >
												<Grid  >
													<Brightness1Icon style={{ color: '#2BAEB3' }} className={`punto ${item.selected}`} />
												</Grid>
												<Grid style={{ fontSize: '15px', textAlign: 'justify', marginLeft:'1%' }} item xs={8} >
												<b>
													{`Costo plataforma: $${formatNumber(Math.floor(item.costo / item.meses))} por cuota`}
													</b>
												</Grid>
												<Grid item xs={1} >
													<div align="right">
														<BootstrapTooltip
															title={
																<React.Fragment>
																	<a style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}><br />{"-Costo de plataforma:"}</a>{' '}
																	<br />
																	<a style={{ fontSize: '16px', color: 'white', textAlign: 'justify' }}>
																		{"Este valor garantiza la disponibilidad de tu cupo de crédito, cubre gastos de instrumentación digital, disponibilidad de plataforma de recaudo y se paga mensualmente mientras tengas uno o más créditos activos."}
																	</a>
																	<br />
																	<br />
																</React.Fragment>
															}
															placement="left-start"
														>
															<InfoIcon style={{ color: '#F29F05' }} className={`punto ${item.selected}`} />
														</BootstrapTooltip>
													</div>
												</Grid>
											</Grid>
																									
												<div style={{ marginTop: "5pt" }}>
													<b className="text-planes" style={{ fontSize: '24px' }}>
													Total a pagar ${`${formatNumber(Math.floor(item.pagoTotal))}`}
													</b>
													<br />										
												</div>																												
										</Grid>
									</div>)}				
									
								</div>)
								}
								)}

									
								<p id="terminos-p">
									{interesCero === 0 ? `*El beneficio del 0% interés aplica mientras se cumpla con
									los pagos dentro de las fechas establecidas.`: null}&nbsp;
									El pago de la fianza y costo de plataforma se pagan mensual sobre cada cuota pagada
									<br></br>
									{commerceData.baas == true ?(
										<Link href={commerceData.configuracionesBaas[2]?.value != null ? commerceData.configuracionesBaas[2]?.value : "https://www.prestapolis.com/consumidor-terminos-y-condiciones"} target="_blank" style={{ color: "#2BAEB3" }} underline="hover">
										Ver términos y condiciones.
									</Link>
									):(<Link href={commerceData.configuracionesBaas != null ? commerceData.configuracionesBaas : "https://www.prestapolis.com/consumidor-terminos-y-condiciones"} target="_blank" style={{ color: "#2BAEB3" }} underline="hover">
									Ver términos y condiciones.
								</Link>)
									
									}

								</p>
							</div>) : (<div></div>)
						)}

						{button || buttonIn ? (
							<div className={classes.wrapper}>
								<Grid container justify="center" style={{ marginTop: '20px', }}>
									<Button
										disabled={false}
										variant="contained"
										onClick={(e) => nextStep()}
										style={{
											textTransform: "none",
											width: "14rem",
											borderRadius: "100px",
											color: '#FFFFFF',
											background: '#2BAEB3'
										}}>
										<strong>
											Continuar
										</strong>
									</Button>

								</Grid>
								{/*isContinuo && <CircularProgress size={24} className={classes.buttonProgress} />*/}
							</div>

						) : (
							<div></div>
						)}

						{isErrorContinue && <Alert severity="error">Usuario o Contraseña no son Validos</Alert>}

					</form>
				</section>
			</div>
		</>
	);

}
